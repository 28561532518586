import axios from "axios";
import { handleAxiosError } from "@/helpers/errors";

export default {
  namespaced: true,
  state: () => ({
    notifications: [],
    notSeenNotifications: [],
    selectedNotification: {},
  }),
  getters: {},
  mutations: {
    setNotifications(state, newNotifications) {
      state.notifications = newNotifications;
    },
    setNotSeenNotifications(state, notifications) {
      state.notSeenNotifications = notifications;
    },
    setSelectedNotification(state, notification) {
      state.selectedNotification = notification;
    },
  },
  actions: {
    async selectNotification(
      { rootState, state, commit, dispatch },
      notification
    ) {
      const isSeen = notification.seen.includes(rootState.user?.user);
      if (!isSeen) {
        await dispatch("markNotificationAsSeen", notification._id);
      }

      commit("setSelectedNotification", notification);
    },
    async fetchNotifications({ rootState, commit }) {
      try {
        const result = await axios.get(
          rootState.appUrl + "notifications/getNotifications"
        );
        if (result.status !== 200) {
          throw new Error(
            `Unexpected status fetching notifications: ${result.status}`
          );
        }

        // console.log("result.data.notifications", result.data.notifications);
        // console.log(" result.data", result.data);
        const notifications = result.data.notifications;
        if (!Array.isArray(notifications)) {
          console.error(
            "invalid notifications notifications/fetchNotifications",
            notifications
          );
          return;
        }

        const currentUser = rootState.user.user;
        const notSeenNotifications = notifications.filter(
          (notification) => !notification.seen.includes(currentUser)
        );

        commit("setNotifications", notifications);
        commit("setNotSeenNotifications", notSeenNotifications);
      } catch (error) {
        handleAxiosError(error);
      }
    },
    async markNotificationAsSeen(
      { rootState, state, commit, dispatch },
      notificationId
    ) {
      try {
        const result = await axios.patch(
          rootState.appUrl + "notifications/markNotificationAsSeen",
          { notificationId }
        );

        if (result.status !== 200) {
          throw new Error(
            `Unexpected status update seen notification: ${result.status}`
          );
        }

        await dispatch("fetchNotifications");
      } catch (error) {
        handleAxiosError(error);
        await dispatch("fetchNotifications");
      }
    },
    async markAsSeenAllNotifications(
      { rootState, state, commit, dispatch },
      unreadNotifications
    ) {
      try {
        const unreadIds = unreadNotifications.map((notif) => notif._id);
        const result = await axios.patch(
          rootState.appUrl + "notifications/markAsSeenAllNotifications",
          { unreadIds }
        );

        if (result.status !== 200) {
          throw new Error(
            `Unexpected status update seen notifications: ${result.status}`
          );
        }

        await dispatch("fetchNotifications");
      } catch (error) {
        handleAxiosError(error);
        await dispatch("fetchNotifications");
      }
    },
  },
};
