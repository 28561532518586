import axios from "axios";
import { handleAxiosError } from "@/helpers/errors";

export default {
  namespaced: true,
  state: () => ({
    loadingProjects: true,
    projects: [],
    selectedProject: null,
    selectedSection: null,
    selectedTask: null,
    projectTasks: [],
    allProjectsTasks: [],
    projectSearch: "",
    allTasksSearch: "",
    projectFilters: [],
    tasksStatuses: [],
    projectCompletedTasks: 0,
    projectUsers: [],
  }),
  mutations: {
    setProjects(state, projects) {
      state.projects = projects;
    },
    setAllProjectsTasks(state, tasks) {
      state.allProjectsTasks = tasks;
    },
    setLoadingProjects(state, loading) {
      state.loadingProjects = loading;
    },
    setSelectedProject(state, project) {
      state.selectedProject = project;

      if (project === null) {
        state.projectTasks = [];
      }
    },
    setSelectedSection(state, section) {
      state.selectedSection = section;
    },
    setProjectUsers(state, members) {
      state.projectUsers = members;
    },
    setSelectedTask(state, task) {
      state.selectedTask = task;
    },
    setProjectTasks(state, tasks) {
      state.projectTasks = tasks;
    },
    setProjectCompletedTasks(state, completedTasks) {
      state.projectCompletedTasks = completedTasks;
    },
    setTasksStatuses(state, statuses) {
      state.tasksStatuses = statuses;
    },
    setSections(state, sections) {
      state.selectedProject.sections = sections;
    },
    setTaskFields(state, fields) {
      state.projectTasks = state.projectTasks.map((task) => {
        if (task._id === fields._id) {
          return { ...task, ...fields };
        }
        return task;
      });
    },
    setProjectSearch(state, search) {
      state.projectSearch = search;
    },
    setAllTasksSearch(state, search) {
      state.allTasksSearch = search;
    },
    setProjectFilters(state, filters) {
      state.projectFilters = filters;
    },
  },
  actions: {
    async fetchProjects({ rootState, state, commit, dispatch }, opts = {}) {
      commit("setLoadingProjects", true);
      if (!opts.refresh) {
        commit("setSelectedProject", null);
      }
      KTApp.blockPage();

      try {
        const result = await axios.get(`${rootState.appUrl}tasks/getProjects`, {
          params: {
            studio: rootState.selectedStudio,
          },
        });
        if (result.status !== 200) {
          throw new Error(
            `Unexpected status fetching projects: ${result.status}`
          );
        }

        const projects = result.data.projects;
        if (!Array.isArray(projects)) {
          console.error("invalid projects tasks/getProjects", projects);
          return;
        }

        commit("setProjects", projects);

        // Preselect first project if none is selected
        if (!state.selectedProject && projects.length > 0) {
          const firstProject = projects[0];
          await dispatch("selectProject", firstProject);
        }
      } catch (error) {
        handleAxiosError(error);
      } finally {
        commit("setLoadingProjects", false);
        KTApp.unblockPage();
      }
    },
    async fetchAllProjectsTasks({ rootState, state, commit, dispatch }) {
      KTApp.blockPage();

      try {
        const result = await axios.get(
          `${rootState.appUrl}tasks/getAllProjectsTasks`,
          {
            params: {
              studio: rootState.selectedStudio,
              search: state.allTasksSearch,
            },
          }
        );
        if (result.status !== 200) {
          throw new Error(
            `Unexpected status fetching projects tasks: ${result.status}`
          );
        }

        const tasks = result.data.tasks;
        if (!Array.isArray(tasks)) {
          console.error("invalid projects tasks/getAllProjectsTasks", tasks);
          return;
        }

        commit("setAllProjectsTasks", tasks);
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async fetchProjectTasks({ rootState, commit }, projectId) {
      KTApp.blockPage();

      try {
        const result = await axios.get(
          `${rootState.appUrl}tasks/getProjectTasks`,
          {
            params: {
              projectId,
            },
          }
        );
        if (result.status !== 200) {
          throw new Error(`Unexpected status fetching tasks: ${result.status}`);
        }

        const tasks = result.data.tasks;
        if (!Array.isArray(tasks)) {
          console.error("invalid tasks tasks/fetchProjectTasks", tasks);
          return;
        }

        let projectUsers = new Set();
        tasks.map((task) => {
          if (task.assignee && task.assignee !== "undefined") {
            projectUsers.add(task.assignee);
          }
          if (task.reporter && task.reporter !== "undefined") {
            projectUsers.add(task.reporter);
          }
          if (task.creator && task.creator !== "undefined") {
            projectUsers.add(task.creator);
          }
        });

        let completedTasks = tasks.filter((task) => task.status === "Done");

        let tasksStatuses = new Set();
        for (const task of tasks) {
          tasksStatuses.add(task.status);
        }
        tasksStatuses = [...tasksStatuses].map((status) => ({ name: status }));

        commit("setTasksStatuses", tasksStatuses);
        commit("setProjectTasks", tasks);
        commit("setProjectCompletedTasks", completedTasks);
        commit("setProjectUsers", Array.from(projectUsers));
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async findProjectById({ state, commit, dispatch }, projectId) {
      const currentProject = state.projects.find(
        (project) => project._id === projectId
      );
    },
    async selectProject({ commit, dispatch }, project) {
      commit("setSelectedProject", project);
      // commit("setProjectTasks", []);

      await dispatch("fetchProjectTasks", project._id);
    },

    async createNewProject({ rootState, commit, dispatch }, body) {
      KTApp.blockPage();

      try {
        // Create new project
        const result = await axios.post(
          rootState.appUrl + "tasks/createNewProject",
          body
        );

        if (result.status !== 201) {
          throw new Error(
            `Unexpected status creating project: ${result.status}`
          );
        }

        // Refresh projects
        commit("setSelectedProject", null);
        await dispatch("fetchProjects");
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async updateProject({ rootState, state, commit, dispatch }, project) {
      KTApp.blockPage();
      const currentProjectId = state.selectedProject._id;
      try {
        const result = await axios.patch(
          rootState.appUrl + "tasks/updateProject",
          {
            _id: currentProjectId,
            name: project.name,
            description: project.description,
          }
        );

        if (result.status !== 200) {
          throw new Error(`Unexpected status update project: ${result.status}`);
        }

        // Refresh project tasks
        if (state.selectedProject) {
          await dispatch("fetchProjects", { refresh: true });
          await dispatch(
            "selectProject",
            state.projects.find((project) => project._id === currentProjectId)
          );
        }
      } catch (error) {
        handleAxiosError(error);
        await dispatch("fetchProjects", { refresh: true });
        await dispatch(
          "selectProject",
          state.projects.find((project) => project._id === currentProjectId)
        );
      } finally {
        KTApp.unblockPage();
      }
    },
    async deleteProject({ rootState, dispatch, commit, state }, body) {
      KTApp.blockPage();
      const currentProjectId = state.selectedProject._id;
      try {
        const result = await axios.delete(
          rootState.appUrl + "tasks/deleteProject/" + body._id
        );

        if (result.status !== 200) {
          throw new Error(
            `Unexpected status deleting project: ${result.status}`
          );
        }

        commit("setSelectedProject", null);
        await dispatch("fetchProjects");
      } catch (error) {
        handleAxiosError(error);
        await dispatch("fetchProjects", { refresh: true });
        await dispatch(
          "selectProject",
          state.projects.find((project) => project._id === currentProjectId)
        );
      } finally {
        KTApp.unblockPage();
      }
    },
    async createSection({ rootState, state, dispatch }, body) {
      KTApp.blockPage();
      const currentProjectId = state.selectedProject._id;
      try {
        // Create new project
        const result = await axios.post(
          rootState.appUrl + "tasks/createSection",
          body
        );

        if (result.status !== 201) {
          throw new Error(
            `Unexpected status creating section: ${result.status}`
          );
        }

        if (state.selectedProject) {
          await dispatch("fetchProjects", { refresh: true });
          await dispatch(
            "selectProject",
            state.projects.find((project) => project._id === currentProjectId)
          );
        }
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },

    async createTask(
      { rootState, dispatch },
      { body, onUploadProgress, files = [] }
    ) {
      KTApp.blockPage();

      try {
        let data = new FormData();

        for (let i of files) {
          data.append("file", i);
        }

        for (const [key, value] of Object.entries(body)) {
          data.append(key, value);
        }

        const result = await axios.post(
          rootState.appUrl + "tasks/createTask",
          data,
          { onUploadProgress }
        );

        if (result.status !== 201) {
          throw new Error(
            `Unexpected status creating section: ${result.status}`
          );
        }

        // Refresh project tasks
        await dispatch("fetchProjectTasks", body.projectId);
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async updateTask(
      { rootState, state, commit, dispatch },
      { body, onUploadProgress, files = [] }
    ) {
      KTApp.blockPage();

      commit("setTaskFields", body);
      try {
        let data = new FormData();
        for (let i of files) {
          data.append("file", i);
        }

        for (const [key, value] of Object.entries(body)) {
          data.append(key, value);
        }
        // Create new project
        const result = await axios.patch(
          rootState.appUrl + "tasks/updateTask",
          data,
          { onUploadProgress }
        );

        if (result.status !== 200) {
          throw new Error(`Unexpected status update task: ${result.status}`);
        }

        // Refresh project tasks
        if (state.selectedProject) {
          await dispatch("fetchProjectTasks", state.selectedProject._id);
        }
      } catch (error) {
        handleAxiosError(error);
        await dispatch("fetchProjectTasks", state.selectedProject._id);
      } finally {
        KTApp.unblockPage();
      }
    },
    async refreshSelectedTask(
      { rootState, state, commit, dispatch },
      taskIdFromNotif
    ) {
      KTApp.blockPage();
      let taskId = taskIdFromNotif ? taskIdFromNotif : state.selectedTask._id;

      try {
        const result = await axios.get(`${rootState.appUrl}tasks/getTask`, {
          params: {
            taskId,
          },
        });
        if (result.status !== 200) {
          throw new Error(`Unexpected status fetching task: ${result.status}`);
        }

        const task = result.data.task;

        commit("setSelectedTask", task);
        commit(
          "setProjectTasks",
          state.projectTasks.map((item) =>
            item._id === task._id ? task : item
          )
        );
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async createSubtask({ rootState, state, commit, dispatch }, body) {
      KTApp.blockPage();

      try {
        const result = await axios.post(
          rootState.appUrl + "tasks/createSubtask",
          body
        );

        if (result.status !== 201) {
          throw new Error(`Unexpected status create subtask: ${result.status}`);
        }

        dispatch("refreshSelectedTask");
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async updateSubtask({ rootState, dispatch }, body) {
      KTApp.blockPage();
      try {
        const result = await axios.patch(
          rootState.appUrl + "tasks/updateSubtask",
          body
        );

        if (result.status !== 200) {
          throw new Error(`Unexpected status update subtask: ${result.status}`);
        }

        dispatch("refreshSelectedTask");
      } catch (error) {
        handleAxiosError(error);
        // await dispatch("fetchProjectTasks", state.selectedProject._id);
      } finally {
        KTApp.unblockPage();
      }
    },
    async deleteSubtask({ rootState, dispatch, state }, subtask) {
      KTApp.blockPage();

      try {
        const result = await axios.delete(
          rootState.appUrl +
            "tasks/deleteSubtask/" +
            subtask._id +
            "/" +
            subtask.subtaskId
        );
        if (result.status !== 200) {
          throw new Error(
            `Unexpected status deleting subtask: ${result.status}`
          );
        }

        dispatch("refreshSelectedTask");
      } catch (error) {
        handleAxiosError(error);
        dispatch("refreshSelectedTask");
      } finally {
        KTApp.unblockPage();
      }
    },
    async createComment(
      { rootState, state, commit, dispatch },
      { body, onUploadProgress, files = [] }
    ) {
      KTApp.blockPage();

      try {
        let data = new FormData();
        for (let i of files) {
          data.append("file", i);
        }

        for (const [key, value] of Object.entries(body)) {
          data.append(key, value);
        }
        // Create new project
        const result = await axios.post(
          rootState.appUrl + "tasks/createComment",
          data,
          { onUploadProgress }
        );

        if (result.status !== 201) {
          throw new Error(`Unexpected status add comment: ${result.status}`);
        }

        dispatch("refreshSelectedTask");
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async updateComment(
      { rootState, dispatch },
      { body, onUploadProgress, files = [] }
    ) {
      KTApp.blockPage();

      try {
        let data = new FormData();
        for (let i of files) {
          data.append("file", i);
        }

        for (const [key, value] of Object.entries(body)) {
          data.append(key, value);
        }
        // Create new project
        const result = await axios.patch(
          rootState.appUrl + "tasks/updateComment",
          data,
          { onUploadProgress }
        );

        if (result.status !== 200) {
          throw new Error(`Unexpected status update comment: ${result.status}`);
        }

        dispatch("refreshSelectedTask");
      } catch (error) {
        handleAxiosError(error);
        // await dispatch("fetchProjectTasks", state.selectedProject._id);
      } finally {
        KTApp.unblockPage();
      }
    },
    async deleteComment({ rootState, dispatch, state }, comment) {
      KTApp.blockPage();
      try {
        const result = await axios.delete(
          rootState.appUrl +
            "tasks/deleteComment/" +
            comment._id +
            "/" +
            comment.taskId
        );
        if (result.status !== 200) {
          throw new Error(
            `Unexpected status deleting comment: ${result.status}`
          );
        }

        dispatch("refreshSelectedTask");
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async deleteTask({ rootState, dispatch, state }, body) {
      KTApp.blockPage();
      const currentProjectId = state.selectedProject._id;
      try {
        const result = await axios.delete(
          rootState.appUrl + "tasks/deleteTask/" + body._id
        );

        if (result.status !== 200) {
          throw new Error(`Unexpected status deleting task: ${result.status}`);
        }

        // Refresh project tasks
        await dispatch("fetchProjectTasks", currentProjectId);
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async deleteTaskAttachment({ rootState, dispatch }, { _id, attachment }) {
      KTApp.blockPage();

      try {
        const result = await axios.delete(
          `${rootState.appUrl}tasks/deleteTaskAttachment/${encodeURIComponent(
            _id
          )}/${encodeURIComponent(attachment)}`
        );

        if (result.status !== 200) {
          throw new Error(
            `Unexpected status deleting task attachment: ${result.status}`
          );
        }

        dispatch("refreshSelectedTask");
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async deleteCommentAttachment(
      { rootState, dispatch },
      { taskId, commentId, attachment }
    ) {
      KTApp.blockPage();

      try {
        const result = await axios.delete(
          `${
            rootState.appUrl
          }tasks/deleteCommentAttachment/${encodeURIComponent(
            commentId
          )}/${encodeURIComponent(taskId)}/${encodeURIComponent(attachment)}`
        );

        if (result.status !== 200) {
          throw new Error(
            `Unexpected status deleting task attachment: ${result.status}`
          );
        }

        dispatch("refreshSelectedTask");
      } catch (error) {
        handleAxiosError(error);
      } finally {
        KTApp.unblockPage();
      }
    },
    async updateSections({ rootState, state, commit, dispatch }, sections) {
      KTApp.blockPage();
      const currentProjectId = state.selectedProject._id;
      try {
        commit("setSections", sections);

        const result = await axios.patch(
          rootState.appUrl + "tasks/updateSections",
          { sections, _id: currentProjectId }
        );

        if (result.status !== 200) {
          throw new Error(
            `Unexpected status update sections: ${result.status}`
          );
        }

        // Refresh project tasks
        if (state.selectedProject) {
          await dispatch("fetchProjects", { refresh: true });
          await dispatch(
            "selectProject",
            state.projects.find((project) => project._id === currentProjectId)
          );
        }
      } catch (error) {
        handleAxiosError(error);
        await dispatch("fetchProjects", { refresh: true });
        await dispatch(
          "selectProject",
          state.projects.find((project) => project._id === currentProjectId)
        );
      } finally {
        KTApp.unblockPage();
      }
    },
    async updateSectionName({ rootState, state, commit, dispatch }, section) {
      KTApp.blockPage();
      const currentProjectId = state.selectedProject._id;
      try {
        const result = await axios.patch(
          rootState.appUrl + "tasks/updateSectionName",
          { _id: currentProjectId, sectionId: section._id, name: section.name }
        );

        if (result.status !== 200) {
          throw new Error(
            `Unexpected status update section name: ${result.status}`
          );
        }

        // Refresh project tasks
        if (state.selectedProject) {
          await dispatch("fetchProjects", { refresh: true });
          await dispatch(
            "selectProject",
            state.projects.find((project) => project._id === currentProjectId)
          );
        }
      } catch (error) {
        handleAxiosError(error);
        await dispatch("fetchProjects", { refresh: true });
        await dispatch(
          "selectProject",
          state.projects.find((project) => project._id === currentProjectId)
        );
      } finally {
        KTApp.unblockPage();
      }
    },
    async deleteSection({ rootState, dispatch, state }, section) {
      KTApp.blockPage();
      const currentProjectId = state.selectedProject._id;
      try {
        const result = await axios.delete(
          rootState.appUrl +
            "tasks/deleteSection/" +
            section._id +
            "/" +
            currentProjectId
        );
        if (result.status !== 200) {
          throw new Error(
            `Unexpected status deleting section: ${result.status}`
          );
        }

        if (state.selectedProject) {
          await dispatch("fetchProjects", { refresh: true });
          await dispatch(
            "selectProject",
            state.projects.find((project) => project._id === currentProjectId)
          );
        }
      } catch (error) {
        handleAxiosError(error);
        await dispatch("fetchProjects", { refresh: true });
        await dispatch(
          "selectProject",
          state.projects.find((project) => project._id === currentProjectId)
        );
      } finally {
        KTApp.unblockPage();
      }
    },
    async deleteSectionTasks({ rootState, dispatch, state }, section) {
      KTApp.blockPage();
      const currentProjectId = state.selectedProject._id;
      try {
        const result = await axios.delete(
          rootState.appUrl +
            "tasks/deleteSectionTasks/" +
            section._id +
            "/" +
            currentProjectId
        );
        if (result.status !== 200) {
          throw new Error(
            `Unexpected status deleting section tasks: ${result.status}`
          );
        }

        if (state.selectedProject) {
          await dispatch("fetchProjects", { refresh: true });
          await dispatch(
            "selectProject",
            state.projects.find((project) => project._id === currentProjectId)
          );
        }
      } catch (error) {
        handleAxiosError(error);
        await dispatch("fetchProjects", { refresh: true });
        await dispatch(
          "selectProject",
          state.projects.find((project) => project._id === currentProjectId)
        );
      } finally {
        KTApp.unblockPage();
      }
    },
  },
};
